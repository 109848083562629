import React from 'react';

/**
 * Images/SVG
 */
import Background from '../images/about.png';
import RadiumJourney from '../images/Radium_Journey.png';
import RadiumVision from '../images/Radium_vision.png';
import RadiumVirtues from '../images/Radium_Strength.png';
import FaisalMember from '../images/faisal.png';
import FredMember from '../images/fred.png';
import AndrejMember from '../images/andrej-porovic.jpg';
import MarcinMember from '../images/marcin-ruman.jpg';
import PawelMember from '../images/pawel-marciniuk.jpg';

/**
 * Components
 */
import SEO from '../components/seo/seo';
import Layout from '../components/layout/layout';
import Banner from '../components/default-banner/default-banner';
import Description from '../components/description/description';
import Vision from '../components/vision/vision';
import Virtues from '../components/virtues/virtues';
import TeamContainer from '../components/team-container/team-container';

export default () => {

  const innerBannerData = {
    backgroundImg: { backgroundImage: `url(${Background}` },
    mainTitle: 'About Us',
    mainText: 
      'We aim to define your brand’s identity and keep you relevant in your industry, now and beyond.'
  };

  const journeyData = { img: <img alt="animation" src={RadiumJourney}/> };

  const visionData = { img: <img alt="animation" src={RadiumVision} /> };

  const virtuesData = { img: <img alt="animation" src={RadiumVirtues} /> };

  /**
   * Array of object's data for team component
   * Spread operator creates new object with correct indexation
   */
  const teamData = [

    {
      teamImg: <img alt="member" src={FaisalMember} />,
      name: 'Faisal Hussain',
      position: 'CEO',
      roles: [  ]
    },
    {
      teamImg: <img alt="member" src={AndrejMember} />,
      name: 'Andrej Porovic',
      position: 'Project Manager',
      roles: [ ]
    },
    {
      teamImg: <img alt="member" src={FredMember} />,
      name: 'Frederico Silva',
      position: 'Full-stack Developer',
      roles: [ 'JS', 'HTML', 'CSS', 'React', 'Wordpress', 'Node.js', 'PhP' ]
    },
    {
      teamImg: <img alt="member" src={MarcinMember} />,
      name: 'Marcin Ruman',
      position: 'Front-End Developer',
      roles: [ 'JS', 'HTML','CSS', 'React', 'Wordpress', 'Shopify' ]
    },
    {
      teamImg: <img alt="member" src={PawelMember} />,
      name: 'Pawoel Marciniuk',
      position: 'Front-End Developer',
      roles: [ 'JS', 'HTML','CSS', 'React', 'Wordpress', 'Shopify' ]
    }
  ];

  return (
    <Layout>
      <SEO 
        title="About" 
        keywords={[ `digital`, `agency`, `technology`, `Radium`, `About` ]} 
      />
      <Banner {...innerBannerData}/>
      <Description {...journeyData}/>
      <Vision {...visionData}/>
      <Virtues {...virtuesData}/>
      <TeamContainer {...teamData}/>
    </Layout>
  )
};
