import React from 'react';

import Styles from './team-template.module.scss';

export default ({ teamImg, name, position, roles }) => (
  
  <div className={Styles.teamMember}>
    {teamImg}
    <div className={Styles.teamProfile}>
      <h3 className={Styles.teamProfileName}>
        <span className={Styles.teamProfileBefore}>Name: </span> 
        {name}
      </h3>
      <p className={Styles.teamProfilePosition}>
        <span className={Styles.teamProfileBefore}>Position: </span>
        {position}
      </p>
      <p>
        {roles.map(( value, index, array ) => {
          if (array.length - 1 === index) {
            return value;
          }
          return value + ', ';
        })}
      </p>
    </div>
  </div>
);
