import React from 'react';

import Styles from './vision.module.scss';

export default ({ img }) => (
  
  <section className={Styles.visionSection}>
    <div className="container">
      <div className={Styles.container}>
        <div className={Styles.visionContainer}>
          <h2 className={Styles.visionTitle}>
            Our Vision
          </h2>
          <p className={Styles.visionText}>
            To lead the frontiers of the digital marketing space and 
            be the ultimate influence to your operational success. 
          </p>
        </div>
        <div className={Styles.imgContainer}>
          <div className={Styles.imgInnerContainer}>
            {img}
          </div>
        </div>
      </div>
    </div>
  </section>
);
