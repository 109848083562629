import React from 'react';

import Styles from './description.module.scss';

export default ({ img }) => (
  
  <section className={Styles.descritionSection}>
    <div className="container">
      <div className={Styles.container}>
        <div className={Styles.animatedContainer}>
          <div className={Styles.animatedInnerContainer}>
            {img}
          </div>
        </div>
        <div className={Styles.descritpionContainer}>
          <h2 className={Styles.descriptionTitle}>
            We tell your story using data, design and technology.
          </h2>
          <p className={Styles.descriptionText}>
            Radium Agency is a team of passionate technologists with an 
            ultimate aim of helping start-ups and brands craft compelling 
            stories through insightful data analysis, creative designs and 
            unique web development. We go against the conventional methods of marketing and explore new ways to distinguish your start-up from 
            others. Our core process uncovers customers’ pain points 
            and helps you define what makes your brand unique. 
          </p>
        </div>
      </div>
    </div>
  </section>
);
