import React from 'react';
import { FaCircle } from "react-icons/fa";

import Styles from './virtues.module.scss';

export default ({ img }) => (
  
  <section className={Styles.virtueSection}>
    <div className="container">
      <div className={Styles.container}>
        <div className={Styles.imgContainer}>
          <div className={Styles.imgInnerContainer}>
            {img}
          </div>
        </div>

        <div className={Styles.virtueContainer}>
          <h2 className={Styles.virtueTitle}>
            Our Strength as a team
          </h2>
          <p className={Styles.virtueText}>
            Our team of experts create unimaginably outstanding and 100% 
            result-oriented solutions that will put your start-up or brand in
            a better position. We pride ourselves on a strong digital team of:
          </p>
          <ul className={Styles.virtueList}>
            <li className={Styles.virtueLine}>
              <FaCircle />Digital Strategists
            </li>
            <li className={Styles.virtueLine}>
              <FaCircle />Data Analyst
            </li>
            <li className={Styles.virtueLine}>
              <FaCircle />User Experience Designer
            </li>
            <li className={Styles.virtueLine}>
              <FaCircle />Brand / Digital Designer
            </li>
            <li className={Styles.virtueLine}>
              <FaCircle />Full-Stack Developers
            </li>
            <li className={Styles.virtueLine}>
              <FaCircle />Optimisation &amp; testers
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
);
