import React from 'react';

import TeamMember from '../team-template/team-template';

import Styles from './team-container.module.scss';

export default ( props ) => (
  
  <section className={Styles.teamSection}>
    <h2 className={Styles.sectionTitle}>
      Meet The Team
    </h2>
    <div className="container">
      <p className={Styles.teamText}>
          We believe successful execution &amp; delivery of digital projects rely on great digital teams who have genuine skills and passion for what they do.
        </p>
      <div className={Styles.teamContainer}>
        {Object.entries(props).map( ( [key, value] ) => 
            <TeamMember key={key} {...value}/>
        )}
      </div>
    </div>
  </section>
);
